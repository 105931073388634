import React, { useContext, useEffect } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import AuthContext from '../../Contexts/Auth/AuthContext';
import AuthProvider from '../../Contexts/Auth/AuthProvider';

const ProtectedRoute = ({ children }) => {

  // console.log('in protected route');
  const token = localStorage.getItem('linkedin-scheduler-token');
  const authContext = useContext(AuthContext);
  const navigate = useNavigate();
  // console.log(authContext);

//   if (!authContext.token) {
//     return <Navigate to="/login" />; // Redirect to login if not authenticated
//   }

  if (!token) {
    return <Navigate to="/login" />; // Redirect to login if not authenticated
  }

  // if(!authContext.userInfo) {
  //   return (<p>Loading...</p>);
  // } 
    return children;
  

  // return (
  //   <>
  //     <AuthProvider>
  //       {children}
  //     </AuthProvider>
  //   </>
  // ); // Render the protected component if authenticated
};

export default ProtectedRoute;