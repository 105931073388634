import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import AuthContext from "../../Contexts/Auth/AuthContext";

const Header = () => {
  const imageStyles = {
    width: '30px',
    height: '30px',
};

const navigate = useNavigate();
const authContext = useContext(AuthContext); 

const logoutHandler = () => {
  localStorage.removeItem('linkedin-scheduler-token');
  window.location.href="/login";
};

  return (
    // <div>
    //   <div>
        <nav className="main-header navbar navbar-expand navbar-white navbar-light">
          <ul className="navbar-nav">
            <li className="nav-item">
              <a
                className="nav-link"
                data-widget="pushmenu"
                href="#"
                role="button">
                <i className="fas fa-bars" />
              </a>
            </li>
              {/* <li className="nav-item d-none d-sm-inline-block">
                <a href="index3.html" className="nav-link">
                  Home
                </a>
              </li>
              <li className="nav-item d-none d-sm-inline-block">
                <a href="#" className="nav-link">
                  Contact
                </a>
              </li> */}
            {/* </i> */}
          </ul>
            {/* Right navbar links */}
            <ul className="navbar-nav ml-auto">
            <li className="nav-item dropdown">
                <a className="nav-link" data-toggle="dropdown" href="#">
                    <img src={authContext?.userInfo?.profile_pic || "/assets/contactImage.png"} style={imageStyles} className="img-size-50 mr-1 user_image img-circle" alt="" />
                    <span className="hidden-xs loggedInUser">
                      {authContext?.userInfo?.first_name + ' ' + authContext?.userInfo?.last_name}
                    </span>
                </a>
                <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
                    <div className="dropdown-item">
                        <div className="media">
                            <img src={authContext?.userInfo?.profile_pic || "/assets/contactImage.png"} className="img-size-50 mr-3 img-circle user_image" alt="" loading="lazy" style={{height: "50px"}} />
                            <div className="media-body">
                                <h3 className="dropdown-item-title loggedInUser my-2">
                                {authContext?.userInfo?.first_name + ' ' + authContext?.userInfo?.last_name}
                                </h3>
                                <p className="text-sm"></p>
                                <button type="button" className="btn btn-primary btn-sm btn-flat m-1" onClick={() => navigate('/panel/profile')}>Profile</button>
                                <button type="button" onClick={logoutHandler} className="btn btn-danger btn-sm btn-flat">Sign Out</button>

                            </div>
                        </div>
                    </div>
                </div>
            </li>              
            </ul>
        </nav>
    // </div>
  );
}

export default Header;
