function Footer() {
    return (
      <div>
        <footer className="main-footer fixed-bottom" style={{padding: 0.5 + 'rem'}}>
            <div className="float-right d-none d-sm-inline-block">
            <b>Version</b> 1.0.0
            </div>
        </footer>
        {/* Control Sidebar */}
        <aside className="control-sidebar control-sidebar-dark">
            {/* Control sidebar content goes here */}
        </aside>
        {/* /.control-sidebar */}
    </div>

    );
  }
  
  export default Footer;