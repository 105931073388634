import { useState, useRef, useContext, useEffect } from "react";
import Loader from "../Loader";
import { Link, useLocation, useNavigate } from "react-router-dom";
import AuthContext from "../../Contexts/Auth/AuthContext";

const Login = () => {
  const navigate = useNavigate();

  useEffect(() => {
    if(localStorage.getItem('linkedin-scheduler-token')) {
      navigate('/panel/dashboard')
    }
  }, [])
  const authContext = useContext(AuthContext);
  // const { storeUserInfo } = useContext(AuthContext);

  const emailRef = useRef();
  const passwordRef = useRef();

  const defaultValidationState = {
    email: { invalid: false, message: 'Please enter email' },
    password: { invalid: false, message: 'Please enter password' },
  };

  const [validateValues, setValidateValues] = useState(defaultValidationState)
  const [response, setResponse] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [passwordFields, setPasswordFields] = useState({
    field: "password", icon: "fa fa-eye",
  });

  const isEmpty = (value) => !value || value.trim().length === 0;

  const submitHandler = async (event) => {
    setValidateValues(defaultValidationState);
    setResponse(null);
    event.preventDefault();

    const email = emailRef.current.value;
    const password = passwordRef.current.value;

    let isValid = true;

    if(isEmpty(email)) {
      setValidateValues((prevState) => {
        return {...prevState, email: {...prevState.email, invalid: true}}
      });
      isValid = false;
    }
    

    if(isEmpty(password)) {
      setValidateValues((prevState) => {
        return {...prevState, password: {...prevState.password, invalid: true}}
      });
      isValid = false;
    }

    const formData = {};
    // const formData = new Object();

    
    if(isValid) {
      setIsLoading(true);
      formData.email = email;
      formData.password = password;
      Object.freeze(formData);

      try {
        const response = await fetch(process.env.REACT_APP_EXPRESS_URL + '/auth/login', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(formData)
        })
  
        if(!response.ok) {
  
          const error = await response.json();
  
          if(response.status === 422) {
  
            console.table(error.errors);
            for (const key of error.errors) {
  
              setValidateValues((prevState) => {
                return {...prevState, [key.path]: {message: [key.msg], invalid: true}}
              });
            }
            
          } else {
            setResponse((prevState) => {
              return {success :false, message: error.message};
            }) 
          }
           
          return;    
        }
        const data = await response.json();

        console.log('login successfull');
        // authContext.storeToken(data.token);
        localStorage.setItem('linkedin-scheduler-token', data.token);
        // authContext.storeUserInfo(data.user);
  
        // setResponse((prevState) => {
        //   return {success :true, message: data.message};
        // })
        return window.location.href = '/panel/dashboard';
        navigate('/panel/dashboard');
        
        // setTimeout(() => {
        //   setResponse(null);
        //   setIsLoading(false); 
        //   // window.location.href = '/panel/dashboard';
        // navigate('/panel/dashboard');
        // }, 10000);
      } catch (error) {
        console.error(error);
        setResponse((prevState) => ({
          success: false,
          message: 'Something went wrong. Please try again.',
        }));
      } finally {
        setIsLoading(false); // Disable loader after receiving response (regardless of success or error)
        setTimeout(() => {
          setResponse(null);
        }, 3000);      }  
    }
  };

  const updatePasswordFieldHandler = (event) => {
    setPasswordFields((prevState) => {
      return {
        field : prevState.field === 'password' ? 'text' : 'password',
        icon : prevState.field === 'password' ? 'fa fa-eye-slash' : 'fa fa-eye'
      }
    })
  }

  // useEffect(() => {
  //   if (authContext.token) {
  //     navigate('/panel/dashboard'); // Redirect to dashboard on successful login
  //   }
  // }, [authContext.token]); 

  const closeAlert = () => setResponse(null);

    return (
      <div className="d-flex justify-content-center align-items-center vh-100">
        <div className="card card-primary">
          <div className="card-header bg-light">
            <h3 className="card-title">Login to start your session</h3>
          </div>
          {isLoading && <Loader />}
          {response && response.success && 
          (<div className="alert alert-success mt-2 alert-dismissible fade show" role="alert">
            {response.message}
            <button type="button" className="close" onClick={closeAlert} aria-hidden="true">x</button>
          </div>)
          }
          {response && !response.success && 
            (<div className="alert alert-danger mt-2 alert-dismissible fade show" role="alert">
              {response.message}
              <button type="button" className="close" onClick={closeAlert} aria-hidden="true">x</button>
            </div>)
          }
          <form onSubmit={submitHandler}>
            <div className="card-body">
              <div className="form-group">
                <label htmlFor="email">Email Address</label>
                <input
                  type="email"
                  autoFocus="true"
                  className={`form-control ${validateValues.email.invalid ? 'is-invalid' : ''}`}
                  id="email"
                  placeholder="Enter email"
                  ref={emailRef}
                  aria-describedby="email-error"
                  aria-invalid="true"
                />
                <span
                    id="email-error"
                    className="error invalid-feedback">
                    {/* // style={{display:"visible"}} */}
                    {validateValues.email.message}
                </span>
              </div>
              <div className="form-group">
              <label htmlFor="password">Password</label>
                <div className="input-group">
                
                <input
                  type={passwordFields.field}
                  className={`form-control ${validateValues.password.invalid ? 'is-invalid' : ''}`}
                  id="password"
                  placeholder="Enter password"
                  ref={passwordRef}
                  aria-describedby="password-error"
                  aria-invalid="true"
                />
                <span class="input-group-text toggle-password" onClick={updatePasswordFieldHandler}><i class={passwordFields.icon}></i></span>
                <span
                    id="password-error"
                    className="error invalid-feedback">
                    {validateValues.password.message}
                </span>
                </div>            
              </div>
              <div className="form-group" style={{margin:1}}>
                <button type="submit" className="btn btn-primary w-100">
                  Login
                </button>
              </div>
              <div className="row mt-1 pl-2">
                <div className="col-6 small">
                    <Link to="/forgot/password">Forgot Password?</Link>
                </div>
                <div className="col-6 text-end small"><Link to="/signup">Register Yourself</Link></div>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  };
  
  export default Login;
  