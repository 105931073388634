import { useState, useRef, useContext } from "react";
import Loader from "../Loader";
import AuthContext from "../../Contexts/Auth/AuthContext";
import { useNavigate } from "react-router-dom";
import classes from "./Profile.module.css";
import UseFetch from "../CustomHooks/useFetch";
const Profile = () => {
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);

  // const firstNameRef = useRef();
  // const lastNameRef = useRef();
  // const emailRef = useRef();
  const currentPasswordRef = useRef();
  const newPasswordRef = useRef();
  const confirmPasswordRef = useRef();
  const profileRef = useRef();

  const defaultValidationStateForProfileUpdate = {
    firstName: { invalid: false, message: "Please enter first name" },
    lastName: { invalid: false, message: "Please enter last name" },
    email: { invalid: false, message: "Please enter email" },
  };

  const defaultValidationStateForPasswordUpdate = {
    currentPassword: { invalid: false, message: "Please enter current password" },
    newPassword: { invalid: false, message: "Please enter new password" },
    confirmPassword: { invalid: false, message: "Please enter confirm password" },
  };

  const [validateValues, setValidateValues] = useState(
    defaultValidationStateForProfileUpdate
  );

  const [validatePasswordValues, setValidatePasswordValues] = useState(
    defaultValidationStateForPasswordUpdate
  );

  const [response, setResponse] = useState(null);
  const [passwordResponse, setPasswordResponse] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingPassword, setIsLoadingPassword] = useState(false);
  const [userValues, setUserValues] = useState({
    firstName: authContext?.userInfo?.first_name,
    lastName: authContext?.userInfo?.last_name,
    email: authContext?.userInfo?.email,
    profilePic: authContext?.userInfo?.profile_pic
  });
  const [passwordFields, setPasswordFields] = useState({
    currentPassword: { field: "password", icon: "fa fa-eye" },
    newPassword: { field: "password", icon: "fa fa-eye" },
    confirmPassword: { field: "password", icon: "fa fa-eye" },
  });
  // const [profilePic, setProfilePic] = useState('');

  const isEmpty = (value) => !value || value.trim().length === 0;

  const submitHandler = async (event) => {
    setValidateValues(defaultValidationStateForProfileUpdate);
    event.preventDefault();

    // const firstName = firstNameRef.current.value;
    // const lastName = lastNameRef.current.value;
    // const email = emailRef.current.value;
    const firstName = userValues.firstName;
    const lastName = userValues.lastName;
    const email = userValues.email;
    const profilePic = userValues.profilePic;
    // const password = passwordRef.current.value;

    let isValid = true;

    if (isEmpty(firstName)) {
      setValidateValues((prevState) => {
        return {
          ...prevState,
          firstName: { ...prevState.firstName, invalid: true },
        };
      });
      isValid = false;
    }

    if (isEmpty(lastName)) {
      setValidateValues((prevState) => {
        return {
          ...prevState,
          lastName: { ...prevState.lastName, invalid: true },
        };
      });
      isValid = false;
    }

    if (isEmpty(email)) {
      setValidateValues((prevState) => {
        return { ...prevState, email: { ...prevState.email, invalid: true } };
      });
      isValid = false;
    }

    // if(isEmpty(password)) {
    //   setValidateValues((prevState) => {
    //     return {...prevState, password: {...prevState.firstName, invalid: true}}
    //   });
    //   isValid = false;
    // }

    const formData = {};
    // const formData = new Object();

    if (isValid) {
      setIsLoading(true);

      formData.firstName = firstName;
      formData.lastName = lastName;
      formData.email = email;
      formData.profilePic = profilePic || '';
      Object.freeze(formData);

      console.table(formData);

        // const {responseData, error, isLoading} = UseFetch("http://localhost:3001/auth/profile-update", {
        //   method: "POST",
        //   headers: {
        //     "Content-Type": "application/json",
        //     Authorization: "Bearer " + authContext.token,
        //   },
        //   body: JSON.stringify(formData),
        // });

        // if(isLoading) {
        //   setIsLoading(true);
        // } else if (error) {
        //   if (error.status === 422) {
        //     console.table(error.errors.errors);
        //     for (const key of error.errors.errors) {
        //       setValidateValues((prevState) => {
        //         return {
        //           ...prevState,
        //           [key.path]: { message: [key.msg], invalid: true },
        //         };
        //       });
        //     }
        //   } else {
        //     setResponse((prevState) => {
        //       return { success: false, message: error.errors.message };
        //     });
        //   }
        //   setIsLoading(false);
        // } else {
        //   setResponse((prevState) => {
        //     return { success: true, message: responseData.message };
        //   });
        //   setIsLoading(false);
        // }


        try {
        const response = await fetch(
          process.env.REACT_APP_EXPRESS_URL + "/auth/profile-update",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + authContext.token,
            },
            body: JSON.stringify(formData),
          }
        );

        if (!response.ok) {
          if(response.status === 401) {
            localStorage.removeItem('linkedin-scheduler-token');
            return window.location.href="/login";
          }
          const error = await response.json();

          if (response.status === 422) {
            console.table(error.errors);
            for (const key of error.errors) {
              setValidateValues((prevState) => {
                return {
                  ...prevState,
                  [key.path]: { message: [key.msg], invalid: true },
                };
              });
            }
          } else {
            setResponse((prevState) => {
              return { success: false, message: error.message };
            });
          }

          return;
        }
        const data = await response.json();

        setResponse((prevState) => {
          return { success: true, message: data.message };
        });

        
      } catch (error) {
        setResponse((prevState) => ({
          success: false,
          message: "Something went wrong. Please try again.",
        }));
      } finally {
        setIsLoading(false);
        setTimeout(() => {
          setResponse(null);
          authContext.setFetchUserInfoAgain(true);
        }, 3000);
      }
    }
  };

  const changeHandler = (event) => {
    setUserValues((prevState) => {
      return { ...prevState, [event.target.id]: event.target.value };
    });
  };

  const passwordUpdateHandler = async (event) => {
    setValidatePasswordValues(defaultValidationStateForPasswordUpdate);
    event.preventDefault();

    const currentPassword = currentPasswordRef.current.value;
    const newPassword = newPasswordRef.current.value;
    const confirmPassword = confirmPasswordRef.current.value;

    let isValidPassword = true;

    if (isEmpty(currentPassword)) {
      setValidatePasswordValues((prevState) => {
        return {
          ...prevState,
          currentPassword: { ...prevState.currentPassword, invalid: true },
        };
      });
      isValidPassword = false;
    }

    if (isEmpty(newPassword)) {
      setValidatePasswordValues((prevState) => {
        return {
          ...prevState,
          newPassword: { ...prevState.newPassword, invalid: true },
        };
      });
      isValidPassword = false;
    }

    if (isEmpty(confirmPassword)) {
      setValidatePasswordValues((prevState) => {
        return {
          ...prevState,
          confirmPassword: { ...prevState.confirmPassword, invalid: true },
        };
      });
      isValidPassword = false;
    }

    const formData = {};

    if (isValidPassword) {
      setIsLoadingPassword(true);

      formData.currentPassword = currentPassword;
      formData.newPassword = newPassword;
      formData.confirmPassword = confirmPassword;
      Object.freeze(formData);

      console.table(formData);

      try {
        const response = await fetch(
          process.env.REACT_APP_EXPRESS_URL + "/auth/password-update",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + authContext.token,
            },
            body: JSON.stringify(formData),
          }
        );

        if (!response.ok) {
          if(response.status === 401) {
            localStorage.removeItem('linkedin-scheduler-token');
            return window.location.href="/login";
          }
          const error = await response.json();

          if (response.status === 422) {
            console.table(error.errors);
            for (const key of error.errors) {
              setValidatePasswordValues((prevState) => {
                return {
                  ...prevState,
                  [key.path]: { message: [key.msg], invalid: true },
                };
              });
            }
          } else {
            setPasswordResponse((prevState) => {
              return { success: false, message: error.message };
            });
          }

          return;
        }
        const data = await response.json();

        setPasswordResponse((prevState) => {
          return { success: true, message: data.message };
        });
      } catch (error) {
        setPasswordResponse((prevState) => ({
          success: false,
          message: "Something went wrong. Please try again.",
        }));
      } finally {
        setIsLoadingPassword(false); // Disable loader after receiving response (regardless of success or error)
        setTimeout(() => {
          setPasswordResponse(null);
          closeAlert();
        }, 3000);
      }
    }
  };

  const PasswordFieldHandler = (id) => {
    setPasswordFields((prevState) => {
      return {
        ...prevState,
        [id]: {
          field: prevState[id].field === "password" ? "text" : "password",
          icon:
            prevState[id].field === "password"
              ? "fa fa-eye-slash"
              : "fa fa-eye",
        },
      };
    });
    // setPasswordFields((prevState) => {
    //   return {...prevState, id: {field : prevState.id.field === 'password' ? 'text' : 'password', icon: prevState.id.field === 'password' ? 'fa fa-eye-slash' : 'fa fa-eye'}}
    // })
  };

  const profilePicChangeHandler = async (event) => {
    // console.log(event.target.files[0]);
    const file = event.target.files[0];

    const formData = new FormData();
    formData.append('image', file);
  
    try {
      const response = await fetch(
        process.env.REACT_APP_EXPRESS_URL + "/api/upload-profile-pic",
        {
          method: "POST",
          headers: {
            Authorization: "Bearer " + authContext.token,
          },
          body: formData,
        }
      );

      if (!response.ok) {
        const error = await response.json();
       
        setResponse((prevState) => {
          return { success: false, message: error.message };
        });

        return;
      }

      const data = await response.json();

      setUserValues((prevState) => {
        return { ...prevState, profilePic: data.imageUrl };
      });

    } catch (error) {
      alert('error');
      setResponse((prevState) => ({
        success: false,
        message: "Something went wrong. Please try again.",
      }));
    } finally {
      // setIsLoading(false);
      // setTimeout(() => {
      //   setResponse(null);
      // }, 3000);
    }
    // console.log(URL.createObjectURL(file));
    // setUserValues((prevState) => {
    //   return { ...prevState, profilePic: URL.createObjectURL(file) };
    // });    
    // setProfilePic(URL.createObjectURL(file));
  }

  const closeAlert = () => setResponse(null) || setPasswordResponse(null);

  return (
    <div className="content-wrapper">
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="m-0">Profile</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <button
                    type="button"
                    className="btn btn-link mr-0 p-0"
                    onClick={() => navigate("/panel/dashboard")}>
                    Home
                  </button>
                </li>
                <li className="breadcrumb-item active">Profile</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-6">
            <div className="card card-default">
              <div className="card-header bg-light">
                <h3 className="card-title">Profile</h3>
              </div>
              {isLoading && <Loader />}
              {response && response.success && (
                <div
                  className="alert alert-success mt-2 alert-dismissible fade show"
                  role="alert">
                  {response.message}
                  <button
                    type="button"
                    className="close"
                    onClick={closeAlert}
                    aria-hidden="true">
                    x
                  </button>
                </div>
              )}
              {response && !response.success && (
                <div
                  className="alert alert-danger mt-2 alert-dismissible fade show"
                  role="alert">
                  {response.message}
                  <button
                    type="button"
                    className="close"
                    onClick={closeAlert}
                    aria-hidden="true">
                    x
                  </button>
                </div>
              )}
              <form onSubmit={submitHandler}>
                <div className="card-body">
                  <div className="text-center">
                    <div
                      className="form-group form-field-template"
                      id="Profile picture">
                      <div className="">
                        <div className="slot-container">
                          <input
                            type="file"
                            ref={profileRef}
                            onChange={profilePicChangeHandler}
                            style={{ display: "none" }}
                            id="profile_pic_input"
                          />
                          <div>
                            <img
                              id="profile_pic"
                              src={userValues.profilePic}
                              className={`profile-user-img img-responsive img-circle img-click ${classes.profilePicHover} ${classes['profile-pic']}`}
                              alt=""
                            />
                          </div>
                          <div className="text-center">
                            <button type="button"
                            onClick={() => profileRef.current.click()}
                              className="my-3 btn btn-primary btn-xs text-center"
                              id="change_profile_pic">
                              Change
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="firstName form-label">First Name</label>
                    <input
                      type="text"
                      className={`form-control ${
                        validateValues.firstName.invalid ? "is-invalid" : ""
                      }`}
                      id="firstName"
                      placeholder="Enter first name"
                      // ref={firstNameRef}
                      value={userValues.firstName}
                      onChange={changeHandler}
                      aria-describedby="firstName-error"
                      aria-invalid="true"
                    />
                    <span
                      id="firstName-error"
                      className="error invalid-feedback">
                      {validateValues.firstName.message}
                    </span>
                  </div>
                  <div className="form-group">
                    <label htmlFor="lastName">Last Name</label>
                    <input
                      type="text"
                      className={`form-control ${
                        validateValues.lastName.invalid ? "is-invalid" : ""
                      }`}
                      id="lastName"
                      placeholder="Enter last name"
                      // ref={lastNameRef}
                      value={userValues.lastName}
                      onChange={changeHandler}
                      aria-describedby="lastName-error"
                      aria-invalid="true"
                    />
                    <span
                      id="lastName-error"
                      className="error invalid-feedback">
                      {validateValues.lastName.message}
                    </span>
                  </div>
                  {/* <div className="form-group">
                            <label for="username" className="col-form-label">Username<span className="text-danger">&nbsp;*</span></label>
                            <input type="text" id="username" className="form-control col-sm-12" value="" placeholder="Username" disabled />
                            <span id="username-error" className="text-danger error-message" style={{display: "none;", marginTop: 4 + "px"}}>Please enter your name</span>
                        </div> */}
                  <div className="form-group">
                    <label htmlFor="email">Email address</label>
                    <input
                      type="email"
                      className={`form-control ${
                        validateValues.email.invalid ? "is-invalid" : ""
                      }`}
                      id="email"
                      placeholder="Enter email"
                      // ref={emailRef}
                      value={userValues.email}
                      onChange={changeHandler}
                      aria-describedby="email-error"
                      aria-invalid="true"
                    />
                    <span id="email-error" className="error invalid-feedback">
                      {validateValues.email.message}
                    </span>
                  </div>
                  <div className="form-group float-left">
                    <button
                      type="submit"
                      id="userSave"
                      className="btn btn-primary ">
                      <i
                        className="fa fa-save"
                        style={{ marginRight: 5 + "px" }}></i>
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>

          <div className="col-md-6">
            <div className="card card-secondary">
              <div className="card-header bg-light">
                <h3 className="card-title ">Change Password</h3>
              </div>
              {isLoadingPassword && <Loader />}
              {passwordResponse && passwordResponse.success && (
                <div
                  className="alert alert-success mt-2 alert-dismissible fade show"
                  role="alert">
                  {passwordResponse.message}
                  <button
                    type="button"
                    className="close"
                    onClick={closeAlert}
                    aria-hidden="true">
                    x
                  </button>
                </div>
              )}
              {passwordResponse && !passwordResponse.success && (
                <div
                  className="alert alert-danger mt-2 alert-dismissible fade show"
                  role="alert">
                  {passwordResponse.message}
                  <button
                    type="button"
                    className="close"
                    onClick={closeAlert}
                    aria-hidden="true">
                    x
                  </button>
                </div>
              )}
              <form onSubmit={passwordUpdateHandler}>
                <div className="card-body">
                  <div className={`form-group ${classes["password-field"]}`}>
                    <label htmlFor="currentPassword" className="col-form-label">
                      Current password
                      <span className="text-danger">&nbsp;*</span>
                    </label>
                    <div
                      className="input-group">
                      <input
                        type={passwordFields.currentPassword.field}
                        id="currentPassword"
                        className={`form-control ${classes["password"]} ${
                          validatePasswordValues.currentPassword.invalid
                            ? "is-invalid"
                            : ""
                        }`}
                        ref={currentPasswordRef}
                        aria-describedby="firstName-error"
                        aria-invalid="true"
                        placeholder="Enter your Current Password"
                      />
                      <span
                        className="input-group-text toggle-password"
                        id="currentPasswordSpan"
                        onClick={() => PasswordFieldHandler("currentPassword")}>
                        <i className={passwordFields.currentPassword.icon}></i>
                      </span>
                      <span
                      id="currentPassword-error"
                      className="error invalid-feedback">
                      {validatePasswordValues.currentPassword.message}
                    </span>
                      {/* <span toggle="#currentPassword" id="current-password" className={`${classes['eye-icon']}`}><i className="fa fa-eye-slash"></i></span> */}
                    </div>
                  </div>
                  <div className={`form-group ${classes["password-field"]}`}>
                    <label htmlFor="newPassword" className="col-form-label">
                      New Password<span className="text-danger">&nbsp;*</span>
                    </label>
                    <div
                      className="input-group">
                      <input
                        type={passwordFields.newPassword.field}
                        id="newPassword"
                        className={`form-control ${classes["password"]} ${
                          validatePasswordValues.newPassword.invalid
                            ? "is-invalid"
                            : ""
                        }`}
                        ref={newPasswordRef}
                        placeholder="Enter Password"
                      />
                      <span
                        className="input-group-text toggle-password"
                        id="newPasswordSpan"
                        onClick={() => PasswordFieldHandler("newPassword")}>
                        <i className={passwordFields.newPassword.icon}></i>
                      </span>
                      <span
                        id="newPassword-error"
                        className="error invalid-feedback">
                        {validatePasswordValues.newPassword.message}
                      </span>
                      {/* <span toggle="#newPassword" id="new-password" className={`${classes['eye-icon']}`}><i className="fa fa-eye-slash"></i></span> */}
                    </div>
                  </div>
                  <div className={`form-group ${classes["password-field"]}`}>
                    <label htmlFor="confirmPassword" className="col-form-label">
                      Confirm Password
                      <span className="text-danger">&nbsp;*</span>
                    </label>
                    <div
                      className="input-group">
                      <input
                        type={passwordFields.confirmPassword.field}
                        id="confirmPassword"
                        className={`form-control ${classes["password"]} ${
                          validatePasswordValues.confirmPassword.invalid
                            ? "is-invalid"
                            : ""
                        }`}
                        ref={confirmPasswordRef}
                        placeholder="Enter Confirm Password"
                      />
                      <span
                        className="input-group-text toggle-password"
                        id="confirmPasswordSpan"
                        onClick={() => PasswordFieldHandler("confirmPassword")}>
                        <i className={passwordFields.confirmPassword.icon}></i>
                      </span>
                      <span
                        id="confirmPassword-error"
                        className="error invalid-feedback">
                        {validatePasswordValues.confirmPassword.message}
                    </span>
                      {/* <span toggle="#confirmPassword" id="confirm-password" className={`${classes['eye-icon']}`}><i className="fa fa-eye-slash"></i></span> */}
                    </div>
                  </div>
                  <div className="form-group float-left">
                  <button
                      type="submit"
                      className="btn btn-primary ">
                      <i
                        className="fa fa-save"
                        style={{ marginRight: 5 + "px" }}></i>
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
