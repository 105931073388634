import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import Home from "../Layout/Home";
import Sidebar from "../Layout/Sidebar";
import { useState, useEffect, useContext } from "react";
import AuthContext from "../../Contexts/Auth/AuthContext";
import { useNavigate } from "react-router-dom";

const Dashboard = () => {
  // console.log('in dashboard main component');
  const [count, setCount] = useState({});
  const authContext = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(process.env.REACT_APP_EXPRESS_URL + "/posts-count", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("linkedin-scheduler-token")
        },
      });

      if (!response.ok) {
        if (response.status === 401) {
          localStorage.removeItem("linkedin-scheduler-token");
          return (window.location.href = "/login");
        }
        return;
      }

      const data = await response.json();

      const array = [];

      data.data.forEach((element) => {
        array[element._id] = element.count;
      });

      setCount(array);
      // console.table(array);

      console.table(data.data);

      // console.log(data.data.client_id,data.data.client_secret,data.data.token)
      // setCount(data.data)
    };

    fetchData();
  }, []);

  return (
    <div className="content-wrapper">
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="m-0">Dashboard</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item active">Home</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-4">
            <div className="small-box bg-info">
              <div className="inner">
                <h3>{count.publish ?? 0}</h3>
                <p>Published Posts</p>
              </div>
              <div className="icon">
                <i className="fas fa-map-pin"></i>
              </div>
              <button className="btn small-box-footer w-100" onClick={() => navigate('/panel/posts')}>
                More info <i className="fas fa-arrow-circle-right"></i>
              </button>
            </div>
          </div>

          <div className="col-4">
            <div className="small-box bg-teal color-palette">
              <div className="inner">
                <h3>{count.draft ?? 0}</h3>
                <p>Draft Posts</p>
              </div>
              <div className="icon">
                <i className="fas fa-clone"></i>
              </div>
              <button className="btn small-box-footer w-100" onClick={() => navigate('/panel/posts')}>
                More info <i className="fas fa-arrow-circle-right"></i>
              </button>
            </div>
          </div>

          <div className="col-4">
            <div className="small-box bg-success">
              <div className="inner">
                <h3>{count.scheduled ?? 0}</h3>
                <p>Scheduled Posts</p>
              </div>
              <div className="icon">
                <i className="fas fa-address-book"></i>
              </div>
              <button className="btn small-box-footer w-100" onClick={() => navigate('/panel/posts')}>
                More info <i className="fas fa-arrow-circle-right"></i>
              </button>
            </div>
          </div>
        </div>
        <div className="row">
          {/* <div className="col-lg-6">
            <div className="card card-primary card-outline">
              <div className="card-body">
                <h5 className="card-title">Card title</h5>
                <p className="card-text">
                  Some quick example text to build on the card title and make up
                  the bulk of the card's content.
                </p>
                <a href="#" className="card-link">
                  Card link
                </a>
                <a href="#" className="card-link">
                  Another link
                </a>
              </div>
            </div>
          </div> */}

          <div className="col-lg-6">
            <div className="card card-outline card-primary">
              <div className="card-header">
                <h5 className="m-0">Want to know how to configure?</h5>
              </div>
              <div className="card-body">
                <ol className="list-group list-group-numbered" style={{paddingLeft: 16 + 'px'}}>
                  <li>
                  Create a page for your linkedin account on which you want to post the content.
                  </li>
                  <li>
                  Login on the <a target="_blank" href="https://developer.linkedin.com/">Linkedin Developer Portal.</a>
                  </li>
                  <li>
                  After logging go to create an app section.
                  </li>
                  <li>
                  Choose a name for your app and enter the url of the page that you created.
                  </li>
                  <li>
                  On successfull creation of the app, you will have to verify tha page once.
                  </li>
                  <li>
                  After that go to auth section of your app, there you will find the client id and client secret. Copy paste them here in inside configurations section.
                  </li>
                  <li>
                  After that using OAuth 2.0 tools, generate a new token and choose the given scopes. Then copy paste that token as well here. You can refer to this <a target="_blank" href="https://learn.microsoft.com/en-us/linkedin/shared/authentication/developer-portal-tools?context=linkedin%2Fcontext">documentation</a> for geenrating a token.
                  </li>
                  <li>
                  Then you will be able to successfully save the configurations.
                  </li>
                </ol>
                <button onClick={() => {navigate("/panel/configurations")}} className="btn btn-primary mt-2">
                  Start configuring
                </button>
              </div>
            </div>
            </div>
            <div className="col-lg-6">
            <div className="card card-primary card-outline">
              <div className="card-header">
                <h5 className="m-0">Want to know how to start creating posts?</h5>
              </div>
              <div className="card-body">
              <ol className="list-group list-group-numbered" style={{paddingLeft: 16 + 'px'}}>
                  <li>
                  Open the posts section and click on the plus icon to create a new post                  </li>
                  <li>
                  Enter a name for the post, it is just for your reference and it will not pe posted on the linkedin.                  </li>
                  <li>
                  Enter the content that you want to post on linedin in the content box                  </li>
                  <li>
                  Select the status as published or draft. If you dont want to publish a post ye then you can choose the draft status.                  </li>
                  <li>
                  If you have selected status as published and you dont select any publish time, then the post will be immediately posted. If you want the content to be posted at some other time then seelct that particular time and post will automatically be posted.                  </li>
                </ol>
                <button onClick={() => {navigate("/panel/post")}} className="btn btn-primary mt-2">
                  Create your first post
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
