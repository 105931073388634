import { useState, useRef, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Loader from "../Loader";
import classes from "../Dashboard/Profile.module.css";

const ChangePassword = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [passwordFields, setPasswordFields] = useState({
    newPassword: { field: "password", icon: "fa fa-eye" },
    confirmPassword: { field: "password", icon: "fa fa-eye" },
  });

  useEffect(() => {
    if(localStorage.getItem('linkedin-scheduler-token')) {
      navigate('/panel/dashboard')
    }
  }, [])

  // const oldPasswordRef = useRef();
  const newPasswordRef = useRef();
  const confirmPasswordRef = useRef();
  //   const passwordRef = useRef();

  const id = location.state?.id;

  // alert(id);
  if (!id) {
    // alert('ni od');
    window.location.href="/404";
    // navigate("/404");
  }

  const defaultValidationState = {
    // oldPassword: { invalid: false, message: "Please enter old password" },
    newPassword: { invalid: false, message: "Please enter new password" },
    confirmPassword: {
      invalid: false,
      message: "Please enter confirm password",
    },
    // password: { invalid: false, message: 'Please enter password' },
  };

  const [validateValues, setValidateValues] = useState(defaultValidationState);

  const [response, setResponse] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const isEmpty = (value) => !value || value.trim().length === 0;

  const submitHandler = async (event) => {
    setValidateValues(defaultValidationState);
    event.preventDefault();

    // const oldPassword = oldPasswordRef.current.value;
    const newPassword = newPasswordRef.current.value;
    const confirmPassword = confirmPasswordRef.current.value;
    // const password = passwordRef.current.value;

    let isValid = true;

    // if (isEmpty(oldPassword)) {
    //   setValidateValues((prevState) => {
    //     return {
    //       ...prevState,
    //       oldPassword: { ...prevState.oldPassword, invalid: true },
    //     };
    //   });
    //   isValid = false;
    // }

    if (isEmpty(newPassword)) {
      setValidateValues((prevState) => {
        return {
          ...prevState,
          newPassword: { ...prevState.newPassword, invalid: true },
        };
      });
      isValid = false;
    }

    if (isEmpty(confirmPassword)) {
      setValidateValues((prevState) => {
        return {
          ...prevState,
          confirmPassword: { ...prevState.confirmPassword, invalid: true },
        };
      });
      isValid = false;
    }

    // if(isEmpty(password)) {
    //   setValidateValues((prevState) => {
    //     return {...prevState, password: {...prevState.firstName, invalid: true}}
    //   });
    //   isValid = false;
    // }

    const formData = {};
    // const formData = new Object();

    if (isValid) {
      setIsLoading(true);

      // formData.oldPassword = oldPassword;
      formData.newPassword = newPassword;
      formData.confirmPassword = confirmPassword;
      formData.id = id;
      Object.freeze(formData);

      console.table(formData);

      try {
        const response = await fetch(
          process.env.REACT_APP_EXPRESS_URL + "/auth/change-password",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(formData),
          }
        );

        if (!response.ok) {
          const error = await response.json();

          if (response.status === 422) {
            console.table(error.errors);
            for (const key of error.errors) {
              setValidateValues((prevState) => {
                return {
                  ...prevState,
                  [key.path]: { message: [key.msg], invalid: true },
                };
              });
            }
          } else {
            setResponse((prevState) => {
              return { success: false, message: error.message };
            });
          }

          setTimeout(() => {
            setResponse(null);
          }, 3000);

          return;
        }
        const data = await response.json();

        setResponse((prevState) => {
          return { success: true, message: data.message };
        });

        setTimeout(() => {
          setResponse(null);
          navigate('/login');
        }, 3000);
      } catch (error) {
        setResponse((prevState) => ({
          success: false,
          message: "Something went wrong. Please try again.",
        }));
      } finally {
        setIsLoading(false); // Disable loader after receiving response (regardless of success or error)
      }
    }
  };

  const PasswordFieldHandler = (id) => {
    setPasswordFields((prevState) => {
      return {
        ...prevState,
        [id]: {
          field: prevState[id].field === "password" ? "text" : "password",
          icon:
            prevState[id].field === "password"
              ? "fa fa-eye-slash"
              : "fa fa-eye",
        },
      };
    });
  };

  const closeAlert = () => setResponse(null);

  return (
    <div className="d-flex justify-content-center align-items-center vh-100">
      <div className="card card-primary">
        <div className="card-header bg-light">
          <h3 className="card-title">Change Password</h3>
        </div>
        {response && response.success && (
          <div
            className="alert alert-success mt-2 alert-dismissible fade show"
            role="alert">
            {response.message}
            <button type="button" class="close" onClick={closeAlert} aria-hidden="true">x</button>
          </div>
        )}
        {response && !response.success && (
          <div
            className="alert alert-danger mt-2 alert-dismissible fade show"
            role="alert">
            {response.message}
            <button type="button" class="close" onClick={closeAlert} aria-hidden="true">x</button>
          </div>
        )}
        {isLoading && <Loader />}
        <form onSubmit={submitHandler}>
          <div className="card-body">
            {/* <div className="form-group">
              <label htmlFor="old-password form-label">Old Password</label>
              <input
                type="password"
                autoFocus="true"
                className={`form-control ${
                  validateValues.oldPassword.invalid ? "is-invalid" : ""
                }`}
                id="old-password"
                placeholder="Enter old password"
                ref={oldPasswordRef}
                aria-describedby="old-password-error"
                aria-invalid="true"
              />
              <span id="old-password-error" className="error invalid-feedback">
                {/* // style={{display:"visible"}} */}
                {/* {validateValues.oldPassword.message} */}
              {/* </span> */}
            {/* </div> */} 

            <div className={`form-group ${classes["password-field"]}`}>
              <label htmlFor="newPassword" className="col-form-label">New Password</label>
              <div className="input-group">
              <input
                // type="password"
                type={passwordFields.newPassword.field}
                autoFocus="true"
                className={`form-control ${classes["password"]} ${
                  validateValues.newPassword.invalid ? "is-invalid" : ""
                }`}
                id="newPassword"
                placeholder="Enter new password"
                ref={newPasswordRef}
                aria-describedby="newPassword-error"
                aria-invalid="true"
              />
              <span
                        className="input-group-text toggle-password"
                        id="newPasswordSpan"
                        onClick={() => PasswordFieldHandler("newPassword")}>
                        <i className={passwordFields.newPassword.icon}></i>
                      </span>
              <span id="newPassword-error" className="error invalid-feedback">
                {validateValues.newPassword.message}
              </span>
              </div>
            </div>
            <div className={`form-group ${classes["password-field"]}`}>
              <label htmlFor="confirmPassword" className="col-form-label">Confirm Password</label>
              <div className="input-group">
              <input
                        type={passwordFields.confirmPassword.field}
                        className={`form-control ${classes["password"]} ${
                  validateValues.confirmPassword.invalid ? "is-invalid" : ""
                }`}
                id="confirmPassword"
                placeholder="Enter confirm password"
                ref={confirmPasswordRef}
                aria-describedby="confirmPassword-error"
                aria-invalid="true"
              />
              <span
                        className="input-group-text toggle-password"
                        id="confirmPasswordSpan"
                        onClick={() => PasswordFieldHandler("confirmPassword")}>
                        <i className={passwordFields.confirmPassword.icon}></i>
                      </span>
              <span
                id="confirmPassword-error"
                className="error invalid-feedback">
                {validateValues.confirmPassword.message}
              </span>
              </div>
            </div>
            {/* <div className="form-group">
              <label htmlFor="password">Password</label>
              <input
                type="password"
                className={`form-control ${validateValues.password.invalid ? 'is-invalid' : ''}`}
                id="password"
                placeholder="Password"
                ref={passwordRef}
                aria-describedby="password-error"
                aria-invalid="true"
              />
              <span
                id="password-error"
                className="error invalid-feedback">
                {validateValues.password.message}
              </span>
            </div> */}
            <div className="form-group">
              <button type="submit" className="btn btn-primary w-100">
                Submit
              </button>
              {/* <span>
                Already Registered..
                <Link to="/login">Login</Link>
              </span> */}
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ChangePassword;
