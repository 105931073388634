import { useState, useRef, useEffect } from "react";
import Loader from "../Loader";
import { Link, useNavigate } from "react-router-dom";

const ForgotPassword = () => {

  const navigate = useNavigate();

  useEffect(() => {
    if(localStorage.getItem('linkedin-scheduler-token')) {
      navigate('/panel/dashboard')
    }
  }, [])

  const emailRef = useRef();

  const defaultValidationState = {
    email: { invalid: false, message: 'Please enter email' },
  };

  const [validateValues, setValidateValues] = useState(defaultValidationState)
  const [response, setResponse] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const isEmpty = (value) => !value || value.trim().length === 0;

  const submitHandler = async (event) => {
    setValidateValues(defaultValidationState);
    setResponse(null);
    event.preventDefault();

    const email = emailRef.current.value;

    let isValid = true;

    if(isEmpty(email)) {
      setValidateValues((prevState) => {
        return {...prevState, email: {...prevState.email, invalid: true}}
      });
      isValid = false;
    }

    const formData = {};
    // const formData = new Object();

    
    if(isValid) {
      setIsLoading(true);
      formData.email = email;
      Object.freeze(formData);

      console.table(formData);

      try {
        const response = await fetch(process.env.REACT_APP_EXPRESS_URL + '/auth/forgot/password', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(formData)
        })
  
        if(!response.ok) {
  
          const error = await response.json();
  
          if(response.status === 422) {
  
            console.table(error.errors);
            for (const key of error.errors) {
  
              setValidateValues((prevState) => {
                return {...prevState, [key.path]: {message: [key.msg], invalid: true}}
              });
            }
            
          } else {
            setResponse((prevState) => {
              return {success :false, message: error.message};
            }) 
          }
           
          setTimeout(() => {
            setResponse(null);
          }, 3000);
          
          return;    
        }
        const data = await response.json();
  
        setResponse((prevState) => {
          return {success :true, message: data.message};
        })
  
        setTimeout(() => {
          setResponse(null);
          navigate('/login');
        }, 3000);
      } catch (error) {
        
        setResponse((prevState) => ({
          success: false,
          message: 'Something went wrong. Please try again.',
        }));
      } finally {
        setIsLoading(false); // Disable loader after receiving response (regardless of success or error)
      }

      
    }
  };

  const closeAlert = () => setResponse(null);

    return (
      <div className="d-flex justify-content-center align-items-center vh-100">
        <div className="card card-primary">
          <div className="card-header bg-light">
            <h3 className="card-title">Forgot Password</h3>
          </div>
          {isLoading && <Loader />}
          {response && response.success && 
          (<div className="alert alert-success mt-2 alert-dismissible fade show" role="alert">
            {response.message}
            <button type="button" class="close" onClick={closeAlert} aria-hidden="true">x</button>
          </div>)
          }
          {response && !response.success && 
            (<div className="alert alert-danger mt-2 alert-dismissible fade show" role="alert">
              {response.message}
              <button type="button" class="close" onClick={closeAlert} aria-hidden="true">x</button>
            </div>)
          }
          <form onSubmit={submitHandler}>
            <div className="card-body">
              <div className="form-group">
                <label htmlFor="email">Email Address</label>
                <input
                  type="email"
                  autoFocus="true"
                  className={`form-control ${validateValues.email.invalid ? 'is-invalid' : ''}`}
                  id="email"
                  placeholder="Enter email"
                  ref={emailRef}
                  aria-describedby="email-error"
                  aria-invalid="true"
                />
                <span
                    id="email-error"
                    className="error invalid-feedback">
                    {/* // style={{display:"visible"}} */}
                    {validateValues.email.message}
                </span>
              </div>
              <div className="form-group" style={{margin:1}}>
                <button type="submit" className="btn btn-primary w-100">
                  Submit
                </button>
              </div>
              <div className="row mt-1 pl-2">
                <div class="text-start">
                    <Link to="/login">Remember Your Password</Link>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  };
  
  export default ForgotPassword;
  