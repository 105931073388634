import { useState, useRef, useEffect } from "react";
import { Link, useNavigate,  } from "react-router-dom";
import Loader from "../Loader";

const Register = () => {

  const navigate = useNavigate();

  useEffect(() => {
    if(localStorage.getItem('linkedin-scheduler-token')) {
      navigate('/panel/dashboard')
    }
  }, [])

  const firstNameRef = useRef();
  const lastNameRef = useRef();
  const emailRef = useRef();
  const passwordRef = useRef();

  const defaultValidationState = {
    firstName: { invalid: false, message: 'Please enter first name' },
    lastName: { invalid: false, message: 'Please enter last name' },
    email: { invalid: false, message: 'Please enter email' },
    password: { invalid: false, message: 'Please enter password' },
  };

  const [validateValues, setValidateValues] = useState(defaultValidationState)

  const [response, setResponse] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [passwordFields, setPasswordFields] = useState({
    field: "password", icon: "fa fa-eye",
  });

  const isEmpty = (value) => !value || value.trim().length === 0;

  const submitHandler = async (event) => {

    setValidateValues(defaultValidationState);
    event.preventDefault();

    const firstName = firstNameRef.current.value;
    const lastName = lastNameRef.current.value;
    const email = emailRef.current.value;
    const password = passwordRef.current.value;

    let isValid = true;

    if(isEmpty(firstName)) {
      setValidateValues((prevState) => {
        return {...prevState, firstName: {...prevState.firstName, invalid: true}}
      });
      isValid = false;
    }
    

    if(isEmpty(lastName)) {
      setValidateValues((prevState) => {
        return {...prevState, lastName: {...prevState.lastName, invalid: true}}
      });
      isValid = false;
    }
    

    if(isEmpty(email)) {
      setValidateValues((prevState) => {
        return {...prevState, email: {...prevState.email, invalid: true}}
      });
      isValid = false;
    }
    

    if(isEmpty(password)) {
      setValidateValues((prevState) => {
        return {...prevState, password: {...prevState.password, invalid: true}}
      });
      isValid = false;
    }

    const formData = {};
    // const formData = new Object();

    
    if(isValid) {
      setIsLoading(true);

      formData.firstName = firstName;
      formData.lastName = lastName;
      formData.email = email;
      formData.password = password;
      Object.freeze(formData);

      console.table(formData);

      try {
        const response = await fetch(process.env.REACT_APP_EXPRESS_URL + '/auth/register', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(formData)
        })
  
        if(!response.ok) {
  
          const error = await response.json();
  
          if(response.status === 422) {
  
            console.table(error.errors);
            for (const key of error.errors) {
  
              setValidateValues((prevState) => {
                return {...prevState, [key.path]: {message: [key.msg], invalid: true}}
              });
            }
            
          } else {
            setResponse((prevState) => {
              return {success :false, message: error.message};
            }) 
          }
           
          return;    
        }
        const data = await response.json();
  
        setResponse((prevState) => {
          return {success :true, message: data.message};
        })
  
        setTimeout(() => {
          setResponse(null);
          navigate('/login');
        }, 3000);
      } catch (error) {
        
        setResponse((prevState) => ({
          success: false,
          message: 'Something went wrong. Please try again.',
        }));
      } finally {
        setIsLoading(false); // Disable loader after receiving response (regardless of success or error)
      }
      

    }
  };

  const updatePasswordFieldHandler = (event) => {
    setPasswordFields((prevState) => {
      return {
        field : prevState.field === 'password' ? 'text' : 'password',
        icon : prevState.field === 'password' ? 'fa fa-eye-slash' : 'fa fa-eye'
      }
    })
  }

  const closeAlert = () => setResponse(null);

  return (
    <div className="d-flex justify-content-center align-items-center vh-100">
      <div className="card card-primary">
        <div className="card-header bg-light">
          <h3 className="card-title">Register</h3>
        </div>
        {isLoading && <Loader />}
          {response && response.success && 
          (<div className="alert alert-success mt-2 alert-dismissible fade show" role="alert">
            {response.message}
            <button type="button" className="close" onClick={closeAlert} aria-hidden="true">x</button>
          </div>)
          }
          {response && !response.success && 
            (<div className="alert alert-danger mt-2 alert-dismissible fade show" role="alert">
              {response.message}
              <button type="button" className="close" onClick={closeAlert} aria-hidden="true">x</button>
            </div>)
          }
        <form onSubmit={submitHandler}>
          <div className="card-body">
            <div className="form-group">
              <label htmlFor="firstName form-label">First Name</label>
              <input
                type="text"
                autoFocus="true"
                className={`form-control ${validateValues.firstName.invalid ? 'is-invalid' : ''}`}
                id="firstName"
                placeholder="Enter first name"
                ref={firstNameRef}
                aria-describedby="firstName-error"
                aria-invalid="true"
              />
              <span
                id="firstName-error"
                className="error invalid-feedback">
                {validateValues.firstName.message}
              </span>
            </div>

            <div className="form-group">
              <label htmlFor="lastName">Last Name</label>
              <input
                type="text"
                className={`form-control ${validateValues.lastName.invalid ? 'is-invalid' : ''}`}
                id="lastName"
                placeholder="Enter last name"
                ref={lastNameRef}
                aria-describedby="lastName-error"
                aria-invalid="true"
              />
              <span
                id="lastName-error"
                className="error invalid-feedback">
                {validateValues.lastName.message}
              </span>
            </div>
            <div className="form-group">
              <label htmlFor="email">Email address</label>
              <input
                type="email"
                className={`form-control ${validateValues.email.invalid ? 'is-invalid' : ''}`}
                id="email"
                placeholder="Enter email"
                ref={emailRef}
                aria-describedby="email-error"
                aria-invalid="true"
              />
              <span
                id="email-error"
                className="error invalid-feedback">
                {validateValues.email.message}
              </span>
            </div>
            <div className="form-group">
              <label htmlFor="password">Password</label>
              <div className="input-group">
                <input
                  type={passwordFields.field}
                  className={`form-control ${validateValues.password.invalid ? 'is-invalid' : ''}`}
                  id="password"
                  placeholder="Password"
                  ref={passwordRef}
                  aria-describedby="password-error"
                  aria-invalid="true"
                />
                <span class="input-group-text toggle-password" onClick={updatePasswordFieldHandler}><i class={passwordFields.icon}></i></span>
                <span
                  id="password-error"
                  className="error invalid-feedback">
                  {validateValues.password.message}
                </span>
              </div>
            </div>
            <div className="form-group">
              <button type="submit" className="btn btn-primary w-100">
                Sign Up
              </button>
              <span>
                Already Registered..
                <Link to="/login">Login</Link>
              </span>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Register;
