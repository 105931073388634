// const NotFound = () => {
//     return (
//         <h3>Sorry the page that you are looking for cannot be found</h3>
//     );
// };

// export default NotFound;


import React from 'react';
import classes from './NotFound.module.css';

const NotFound = ({ error }) => {
  return (
    <div className={`${classes['error-page']}`}>
      <h1 className={`${classes['error-page-h1']}`}>Oops! Something went wrong.</h1>
      {error && <p className={`${classes['error-page-p']}`}>{error.message || 'Sorry the page that you are looking for cannot be found.'}</p>}
      <button className={`${classes['button']} ${classes['buttonHover']}`} onClick={() => window.location.reload()}>Reload</button>
    </div>
  );
};

export default NotFound;