import React from "react";

const AuthContext = React.createContext({
    // isLoggedIn: false,
    // storeUserInfo: (user) => {},
    userInfo : {},
    token: null,
    // storeToken : (token) => {},
    fetchUserInfoAgain: false,
    setFetchUserInfoAgain : (value) => {}
});

export default AuthContext;