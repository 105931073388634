import { useState, useRef, useContext, useEffect } from "react";
import Loader from "../Loader";
import AuthContext from "../../Contexts/Auth/AuthContext";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "react-tooltip";

const ConfigurationsCreateUpdate = () => {
    const navigate = useNavigate();
    const authContext = useContext(AuthContext);
    const tokenRef = useRef();

  const defaultValidationState = {
    clientId: { invalid: false, message: "Please enter client id" },
    clientSecret: { invalid: false, message: "Please enter client secret" },
    token: { invalid: false, message: "Please enter token" },
  };

  const [validateValues, setValidateValues] = useState(
    defaultValidationState
  );

  const [response, setResponse] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [configurationValues, setConfigurationValues] = useState({
    clientId: '',
    clientSecret: '',
    token: '',
    id: '',
  });

  const [passwordFields, setPasswordFields] = useState({
    field: "password", icon: "fa fa-eye",
  });

  useEffect(() => {
    const fetchData = async () => {
        const response = await fetch(
            process.env.REACT_APP_EXPRESS_URL + "/configurations",
            {
                method: "GET",
                headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + authContext.token,
                },
            }
        );

        if(!response.ok) {
            if(response.status === 401) {
                localStorage.removeItem('linkedin-scheduler-token');
                return window.location.href="/login";
            }
            const error = await response.json();

            setResponse((prevState) => {
                return { success: false, message: error.message };
            });
            return;
        } 

        const data = await response.json();

        // console.log(data.data.client_id,data.data.client_secret,data.data.token)
        setConfigurationValues({
            clientId: data?.data?.client_id,
            clientSecret: data?.data?.client_secret,
            token: data?.data?.token,
            id: data?.data?._id
        })
    }

    fetchData();
  }, []);

  const isEmpty = (value) => !value || value.trim().length === 0;

  const submitHandler = async (event) => {
    setValidateValues(defaultValidationState);
    event.preventDefault();

    const clientId = configurationValues.clientId;
    const clientSecret = configurationValues.clientSecret;
    const token = configurationValues.token;

    let isValid = true;

    if (isEmpty(clientId)) {
      setValidateValues((prevState) => {
        return {
          ...prevState,
          clientId: { ...prevState.clientId, invalid: true },
        };
      });
      isValid = false;
    }

    if (isEmpty(clientSecret)) {
      setValidateValues((prevState) => {
        return {
          ...prevState,
          clientSecret: { ...prevState.clientSecret, invalid: true },
        };
      });
      isValid = false;
    }

    if (isEmpty(token)) {
      setValidateValues((prevState) => {
        return { ...prevState, token: { ...prevState.token, invalid: true } };
      });
      isValid = false;
    }

    const formData = {};

    if (isValid) {
      setIsLoading(true);

      formData.clientId = clientId;
      formData.clientSecret = clientSecret;
      formData.token = token;
      if(configurationValues.id) {
        formData.id = configurationValues.id;
      }
      Object.freeze(formData);

      console.table(formData);

        try {
        const response = await fetch(
          process.env.REACT_APP_EXPRESS_URL + "/configurations",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + authContext.token,
            },
            body: JSON.stringify(formData),
          }
        );

        if (!response.ok) {
          if(response.status === 401) {
            localStorage.removeItem('linkedin-scheduler-token');
            return window.location.href="/login";
          }
          const error = await response.json();

          if (response.status === 422) {
            console.table(error.errors);
            for (const key of error.errors) {
              setValidateValues((prevState) => {
                return {
                  ...prevState,
                  [key.path]: { message: [key.msg], invalid: true },
                };
              });
            }
          } else {
            setResponse((prevState) => {
              return { success: false, message: error.message };
            });
          }

          return;
        }
        const data = await response.json();

        setResponse((prevState) => {
          return { success: true, message: data.message };
        });

        
      } catch (error) {
        setResponse((prevState) => ({
          success: false,
          message: "Something went wrong. Please try again.",
        }));
      } finally {
        setIsLoading(false);
        setTimeout(() => {
          setResponse(null);
        }, 3000);
      }
    }
  };

  const changeHandler = (event) => {
    setConfigurationValues((prevState) => {
      return { ...prevState, [event.target.id]: event.target.value };
    });
  };

  const updatePasswordFieldHandler = (event) => {
    setPasswordFields((prevState) => {
      return {
        field : prevState.field === 'password' ? 'text' : 'password',
        icon : prevState.field === 'password' ? 'fa fa-eye-slash' : 'fa fa-eye'
      }
    })
  }

  const closeAlert = () => setResponse(null);


    return (
        <div className="content-wrapper">
          <div className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">
                  <h1 className="m-0">Configurations</h1>
                </div>
                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-right">
                    <li className="breadcrumb-item">
                      <button
                        type="button"
                        className="btn btn-link mr-0 p-0"
                        onClick={() => navigate("/panel/dashboard")}>
                        Home
                      </button>
                    </li>
                    <li className="breadcrumb-item active">Configurations</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="card card-default">
                  <div className="card-header bg-light">
                    <h3 className="card-title">Configurations</h3>
                  </div>
                  {isLoading && <Loader />}
                  {response && response.success && (
                    <div
                      className="alert alert-success mt-2 alert-dismissible fade show"
                      role="alert">
                      {response.message}
                      <button
                        type="button"
                        className="close"
                        onClick={closeAlert}
                        aria-hidden="true">
                        x
                      </button>
                    </div>
                  )}
                  {response && !response.success && (
                    <div
                      className="alert alert-danger mt-2 alert-dismissible fade show"
                      role="alert">
                      {response.message}
                      <button
                        type="button"
                        className="close"
                        onClick={closeAlert}
                        aria-hidden="true">
                        x
                      </button>
                    </div>
                  )}
                  <form onSubmit={submitHandler}>
                    <div className="card-body">
                      <div className="form-group">
                        <label htmlFor="clientId form-label">Client ID <i className="fas fa-question-circle" style={{ cursor: 'pointer' }} data-tooltip-id="clientId-tooltip" data-tooltip-html="Enter Client ID from <br> the developer portal."></i></label>
                        <Tooltip id="clientId-tooltip" />
                        <input
                          type="text"
                          autoFocus="true"
                          className={`form-control ${
                            validateValues.clientId.invalid ? "is-invalid" : ""
                          }`}
                          id="clientId"
                          placeholder="Enter client id"
                          value={configurationValues.clientId}
                          onChange={changeHandler}
                          aria-describedby="clientId-error"
                          aria-invalid="true"
                        />
                        <span
                          id="clientId-error"
                          className="error invalid-feedback">
                          {validateValues.clientId.message}
                        </span>
                      </div>
                      <div className="form-group">
                        <label htmlFor="clientSecret">Client Secret <i className="fas fa-question-circle" style={{ cursor: 'pointer' }} data-tooltip-id="clientSecret-tooltip" data-tooltip-html="Enter Client Secret from <br> the developer portal."></i></label>
                        <Tooltip id="clientSecret-tooltip" />
                        <input
                          type="text"
                          className={`form-control ${
                            validateValues.clientSecret.invalid ? "is-invalid" : ""
                          }`}
                          id="clientSecret"
                          placeholder="Enter client secret"
                          value={configurationValues.clientSecret}
                          onChange={changeHandler}
                          aria-describedby="clientSecret-error"
                          aria-invalid="true"
                        />
                        <span
                          id="clientSecret-error"
                          className="error invalid-feedback">
                          {validateValues.clientSecret.message}
                        </span>
                      </div>
                        <div className="form-group">
                                    <label htmlFor="token">Token <i className="fas fa-question-circle" style={{ cursor: 'pointer' }} data-tooltip-id="token-tooltip" data-tooltip-html="Enter token generated using Open <br> Auth 2.0 toolkit in the developer portal."></i></label>
                                    <Tooltip id="token-tooltip" style={{marginLeft: 70 + 'px'}}/>

                                        <div className="input-group">
                                        
                                        <input
                                        type={passwordFields.field}
                                        className={`form-control ${validateValues.token.invalid ? 'is-invalid' : ''}`}
                                        id="token"
                                        placeholder="Enter token"
                                        onChange={changeHandler}
                                        ref={tokenRef}
                                        aria-describedby="token-error"
                                        aria-invalid="true"
                                        value={configurationValues.token}
                                        />
                                        <span className="input-group-text toggle-password" onClick={updatePasswordFieldHandler}><i className={passwordFields.icon}></i></span>
                                        <span
                                            id="token-error"
                                            className="error invalid-feedback">
                                            {validateValues.token.message}
                                        </span>
                                        </div>            
                                    </div>                    
                      <div className="form-group float-left">
                        <button
                          type="submit"
                          id="userSave"
                          className="btn btn-primary ">
                          <i
                            className="fa fa-save"
                            style={{ marginRight: 5 + "px" }}></i>
                          Submit
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
};

export default ConfigurationsCreateUpdate;
