import { useContext, useState } from "react";
import AuthContext from "../../Contexts/Auth/AuthContext";
import { useLocation, useNavigate } from "react-router-dom";
import classes from "./sidebar.module.css";

const Sidebar = () => {
  // const [isHovered, setIsHovered] = useState(false);

  // const handleMouseEnter = () => setIsHovered(true);
  // const handleMouseLeave = () => setIsHovered(false);

  // const buttonStyles = {
  //   color: isHovered ? "#fff" : "#C2C7D0",
  //   // Add other styles as needed (e.g., border, background-color)
  // };

  const authContext = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();

  console.table(location);

  return (
    <aside className="main-sidebar sidebar-dark-primary elevation-4 position-fixed">
      {/* Brand Logo */}
      <a href="/panel/dashboard" className="brand-link">
        <img
          src="/assets/linkedin-logo.jpg"
          alt="LinkedIn Logo"
          className="brand-image img-circle elevation-3"
          style={{ opacity: ".8" , width:"auto", height: "auto"}}
        />
        <span className="brand-text font-weight-light">LinkedIn Scheduler</span>
      </a>
      {/* Sidebar */}
      <div className="sidebar os-theme-light">
        {/* Sidebar user panel (optional) */}
        <div className="user-panel mt-3 pb-3 mb-3 d-flex">
          <div className="image">
            <img
              src={
                authContext?.userInfo?.profile_pic || "/assets/contactImage.png"
              }
              className="img-circle elevation-2 user_image"
              style={{ width: 30 + "px", height: 30 + "px" }}
              alt="User Image"
            />
          </div>
          <div className="info">
            <a href="/panel/profile" className="d-block">
              {authContext?.userInfo?.first_name +
                " " +
                authContext?.userInfo?.last_name}
            </a>
          </div>
        </div>
        {/* Sidebar Menu */}
        <nav className="mt-2">
          <ul
            className="nav nav-pills nav-sidebar flex-column"
            data-widget="treeview"
            role="menu"
            data-accordion="false">
            {/* Add icons to the links using the .nav-icon class
         with font-awesome or any other icon font library */}
            {/* <li className="nav-item menu-open">
            <a href="#" className="nav-link active">
              <i className="nav-icon fas fa-tachometer-alt" />
              <p>
                Dashboard
                <i className="right fas fa-angle-left" />
              </p>
            </a>
            <ul className="nav nav-treeview">
              <li className="nav-item">
                <a href="./index.html" className="nav-link active">
                  <i className="far fa-circle nav-icon" />
                  <p>Dashboard v1</p>
                </a>
              </li>
              <li className="nav-item">
                <a href="./index2.html" className="nav-link">
                  <i className="far fa-circle nav-icon" />
                  <p>Dashboard v2</p>
                </a>
              </li>
              <li className="nav-item">
                <a href="./index3.html" className="nav-link">
                  <i className="far fa-circle nav-icon" />
                  <p>Dashboard v3</p>
                </a>
              </li>
            </ul>
          </li> */}
            <li className="nav-item">
              {/* <a href="pages/widgets.html" className="nav-link active"> */}
              <button
                type="button"
                className={`text-left btn w-100 nav-link ${classes.buttonHover} ${location.pathname === '/panel/dashboard' ? 'active active-button' : classes.button}`}
                onClick={() => navigate('/panel/dashboard')}>
                <i className="nav-icon fas fa-th" />
                <p>Dashboard</p>
              </button>
            </li>
            <li className="nav-item">
              {/* <a href="pages/widgets.html" className="nav-link">
              <i className="nav-icon fas fa-cog" />
              <p>
                Configurations
              </p>
            </a> */}
              <button
                type="button"
                className={`text-left btn w-100 nav-link ${classes.buttonHover} ${location.pathname === '/panel/configurations' ? 'active active-button' : classes.button}`}
                onClick={() => navigate('/panel/configurations')}>
                <i className="nav-icon fas fa-cog" />
                <p>Configurations</p>
              </button>
            </li>
            <li className="nav-item">
              {/* <a href="pages/widgets.html" className="nav-link">
              <i className="nav-icon fas fa-globe" />
              <p>
                Posts
              </p>
            </a> */}
            {/* ['/panel/posts', '/panel/post', '/panel/edit/'].includes(location.pathname) */}
              <button
                type="button"
                className={`text-left btn w-100 nav-link ${classes.buttonHover} ${(['/panel/posts', '/panel/post'].includes(location.pathname) || location.pathname.startsWith('/panel/post/edit/')) ? 'active active-button' : classes.button}`}
                onClick={() => navigate('/panel/posts')}>
                <i className="nav-icon fas fa-cog" />
                <p>Posts</p>
              </button>
            </li>
          </ul>
        </nav>
      </div>
    </aside>
  );
};

export default Sidebar;
