import { useState, useRef, useEffect } from "react";
import Loader from "../Loader";
import { Link, redirect, useNavigate, useParams } from "react-router-dom";

const ResetLink = () => {
  const params = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    const formData = {
      id: params.id,
      otp: params.otp,
    };

    async function sendOtpForVerification(formData) {
      try {
        const response = await fetch(process.env.REACT_APP_EXPRESS_URL + "/auth/verify-otp", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        });

        if (!response.ok) {
          const error = await response.json();
          console.error(error);

          navigate("/login");

          return;
        }

        const data = await response.json();

        navigate("/change/password", { state: {id: data.userId} });
      } catch (error) {
        console.log(error);
      }
    }
    sendOtpForVerification(formData);
  }, [navigate, params.id, params.otp]);

  return <></>;
};

export default ResetLink;
