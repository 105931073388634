import { useNavigate } from "react-router-dom";
import classes from "./Post.module.css";
import { useRef } from "react";

const Post = ({data, deletePost}) => {
    const navigate = useNavigate();
    const callDeletePosthandler = () => {
      closeButtonRef.current.click();
        return deletePost(data._id);
    }
    const closeButtonRef = useRef();
  return (
      <>
      <div className="modal fade" id="deleteModal" data-backdrop="static" data-keyboard="false" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered" role="document">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">Delete Post</h5>
        <button type="button" ref={closeButtonRef} className="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        Are you sure you want to delete this post?
      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn-danger" onClick={callDeletePosthandler}>Delete</button>
      </div>
    </div>
  </div>
</div>
    <div className={`${classes["post-list"]}`}>
      <div className={`${classes["post-card"]}`}>
        <div className={`${classes["post-content"]}`}>
          <h5>{data.name}</h5>
          <p>
            {data.content.length > 280 ? data.content.substring(0,281) + '...': data.content}
          </p>
        </div>
        <div className={`${classes["post-info"]}`}>
            {data.status === 'publish' && data.scheduled === 0 ? <span className={`${classes["post-status"]} ${classes.published}`}>Published</span> : (data.status === 'publish' && data.scheduled === 1 ? <span className={`${classes["post-status"]} ${classes.scheduledForPublish}`}>Scheduled for publish</span> : <span className={`${classes["post-status"]} ${classes.draft}`}>Draft</span>)}
          
          {/* <span className={`${classes["post-time"]}`}>
                        1 hour ago
                      </span> */}
          <div className={`${classes["post-actions"]}`}>
            <button className="btn btn-outline-secondary" onClick={() => {navigate('/panel/post/edit/' + data._id)}}>
              <i className="fa fa-edit"></i>Edit
            </button>
            {/* <button className="btn btn-outline-secondary" onClick={callDeletePosthandler}> */}
            <button className="btn btn-outline-secondary" type="button" data-toggle="modal" data-target="#deleteModal">

              <i className="fa fa-trash"></i>Delete
            </button>
          </div>
        </div>
      </div>
    </div>
    </>

  );
};

export default Post;
