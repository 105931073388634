import {createBrowserRouter, Navigate, Route, RouterProvider} from 'react-router-dom';

import Register from './Components/Auth/Register';
import Login from './Components/Auth/Login';
import ForgotPassword from './Components/Auth/ForgotPassword';
import ResetLink from './Components/Auth/ResetLink';
import ChangePassword from './Components/Auth/ChangePassword';
import Dashboard from './Components/Dashboard/Dashboard';
import DashboardNavigation from './Components/Layout/DashboardNavigation';
import Profile from './Components/Dashboard/Profile';
import AuthProvider from './Contexts/Auth/AuthProvider';
import ProtectedRoute from './Components/Dashboard/ProtectedRoute';
import NotFound from './Components/Error/NotFound';
import ConfigurationsCreateUpdate from './Components/Configurations/ConfigurationsCreateUpdate';
import PostCreateUpdate from './Components/Posts/PostCreateUpdate';
import PostList from './Components/Posts/PostList';

function App() {
  // const authContext = useContext(AuthContext);

  const routes = createBrowserRouter([
    {path: '/', errorElement: <NotFound />, children: [
      {index:true, element: <Login/>},
      {path: 'login', element: <Login/>},
      {path: 'signup', element: <Register/>},
      {path: 'forgot/password', element: <ForgotPassword/>},
      {path: 'reset-link/:id/:otp', element: <ResetLink/>},
      {path: 'change/password', element: <ChangePassword />}
    ]},
    {
      path: '/panel/',
      element:
      <ProtectedRoute>
        <DashboardNavigation />
      </ProtectedRoute>,
      children: [ // Define nested routes for dynamic content
        { path: 'dashboard', element: <Dashboard /> },
        { path: 'profile', element: <Profile /> },
        { path: 'configurations', element: <ConfigurationsCreateUpdate /> },
        { path: 'posts', element: <PostList /> },
        { path: 'post', element: <PostCreateUpdate /> },
        { path: 'post/edit/:id', element: <PostCreateUpdate /> },
      ]
    },

    // {
    //   path: '/panel/',
    //   element: <ProtectedRoute> {/* Wrap protected routes with ProtectedRoute */}
    //     <DashboardNavigation>
    //       {/* Child routes here */}
    //       <Route path="dashboard" element={<Dashboard />} />
    //       <Route path="profile" element={<Profile />} />
    //     </DashboardNavigation>
    //   </ProtectedRoute>,
    // },

    // to pass component dynamically
    // {path: '/panel/', children: [
    //   {path: 'dashboard', element: authContext.token ? <DashboardNavigation Component={Dashboard}/> : <Navigate to="/login" />},
    //   {path: 'profile', element: authContext.token ? <DashboardNavigation Component={Profile}/> : <Navigate to="/login" />},
    // ]}
  ]);

  return (
    <AuthProvider>
      <RouterProvider router={routes} />
    </AuthProvider>
  );
}

export default App;
