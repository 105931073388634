import { useState, useContext, useEffect } from "react";
import Loader from "../Loader";
import AuthContext from "../../Contexts/Auth/AuthContext";
import { useNavigate, useParams } from "react-router-dom";
import classes from "./Post.module.css";
import { Tooltip } from 'react-tooltip';

const PostCreateUpdate = () => {
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);
  const [isEdit, setIsEdit] = useState(false);

  const params = useParams();
  const id = params.id;

  // if(id) {
  //   setIsEdit(true);
  // }


  const defaultValidationState = {
    content: {
      invalid: false,
      message: "Please enter content that you want to post",
    },
    name: {
      invalid: false,
      message: "Please enter name of a post for reference",
    },
    status: { invalid: false, message: "Please select status" },
    publishTime: { invalid: false, message: "Please choose publish time" },
  };

  const [validateValues, setValidateValues] = useState(defaultValidationState);

  const [response, setResponse] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [postValues, setPostValues] = useState({
    content: "",
    name: "",
    status: "publish",
    publishTime: "",
    id: "",
  });

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(process.env.REACT_APP_EXPRESS_URL + "/post/"+id, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + authContext.token,
        },
      });

      if (!response.ok) {
        if (response.status === 401) {
          localStorage.removeItem("linkedin-scheduler-token");
          return (window.location.href = "/login");
        }
        const error = await response.json();

        setResponse((prevState) => {
          return { success: false, message: error.message };
        });
        return;
      }

      const data = await response.json();

      // console.log(data.data.client_id,data.data.client_secret,data.data.token)
      setPostValues({
        name: data?.data?.name,
        content: data?.data?.content,
        status: data?.data?.status,
        id: data?.data?._id,
        publishTime: data?.data?.publish_time,
      });
    };

    if(id) {
      setIsEdit(true);
      fetchData();
    }
  }, [id]);

  const isEmpty = (value) => !value || value.trim().length === 0;

  const submitHandler = async (event) => {
    setValidateValues(defaultValidationState);
    event.preventDefault();

    const content = postValues.content;
    const status = postValues.status;
    const publishTime = postValues.publishTime;
    const name = postValues.name;

    let isValid = true;

    if (isEmpty(content)) {
      setValidateValues((prevState) => {
        return {
          ...prevState,
          content: { ...prevState.content, invalid: true },
        };
      });
      isValid = false;
    }

    if (isEmpty(status)) {
      setValidateValues((prevState) => {
        return {
          ...prevState,
          status: { ...prevState.status, invalid: true },
        };
      });
      isValid = false;
    }

    if (isEmpty(name)) {
      setValidateValues((prevState) => {
        return {
          ...prevState,
          name: { ...prevState.name, invalid: true },
        };
      });
      isValid = false;
    }

    const formData = {};

    if (isValid) {
      setIsLoading(true);

      formData.content = content;
      formData.name = name;
      formData.status = status;
      formData.publishTime = publishTime;
      if (postValues.id) {
        formData.id = postValues.id;
      }
      Object.freeze(formData);

      console.table(formData);

      try { 

        const response = await fetch(process.env.REACT_APP_EXPRESS_URL + "/create-post", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + authContext.token,
          },
          body: JSON.stringify(formData),
        });

      //   const response = await fetch('https://api.linkedin.com/v2/userinfo', {
      //     method: 'GET',
      //     headers: {
      //         // 'X-RestLi-Method': 'finder',
      //         Authorization: 'Bearer AQXFwDmyxEMOvyWiDkLy5J8EllvCbQc1v8MDlmDwPMvngVl5W42nM2piQnUpiryhsyDXDadMnBM1-NeA8g8tZ4cTjJszEcDcoEx6xCwP-sICtOWOqkfoLVK1ieUBjQ9ISg5cEL2AkItT3dtcciyaYpN2KYfrtVjQgIwkmdTco5kAhK6NDcF0yy83bx4ElKCQvVaJD5LLCZUA5m5zcqgKdEX-JQBQkRVAwivY5mFIjvb8bSf4inRiOye6_G_aPu6WojtBR71knTg2uudmhxpJ5NcyhJRLgdbGgTHbEICKrelvetnI9q9sP4AS-GV4BOsrDXPLdhwrgzdNnwLBfvfO7AAaim4Ckg',
      //         // 'Connection': 'Keep-Alive',
      //         'Content-Type': 'application/json',
      //         'LinkedIn-Version': '202304',
      //         'X-Restli-Protocol-Version': '2.0.0',
      //         // 'Access-Control-Allow-Origin' : '*'
      //     },
      //     timeout: 10000 // Set timeout to 10 seconds
      // });
        if (!response.ok) {
          if (response.status === 401) {
            localStorage.removeItem("linkedin-scheduler-token");
            return (window.location.href = "/login");
          }
          const error = await response.json();
          console.error(error);

          if (response.status === 422) {
            console.table(error.errors);
            for (const key of error.errors) {
              setValidateValues((prevState) => {
                return {
                  ...prevState,
                  [key.path]: { message: [key.msg], invalid: true },
                };
              });
            }
          } else {
            setResponse((prevState) => {
              return { success: false, message: error.message };
            });
          }

          return;
        }
        const data = await response.json();

        setResponse((prevState) => {
          return { success: true, message: data.message };
        });
        setTimeout(() => {
          navigate('/panel/posts');
        },3000)
      } catch (error) {
        console.error('error',error);
        setResponse((prevState) => ({
          success: false,
          message: "Something went wrong. Please try again.",
        }));
      } finally {
        setIsLoading(false);
        setTimeout(() => {
          setResponse(null);
        }, 3000);
      }
    }
  };

  const changeHandler = (event) => {
    setPostValues((prevState) => {
      return { ...prevState, [event.target.name]: event.target.value };
    });
  };

  const closeAlert = () => setResponse(null);

  return (
    <div className="content-wrapper">
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="m-0">Post</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <button
                    type="button"
                    className="btn btn-link mr-0 p-0"
                    onClick={() => navigate("/panel/dashboard")}>
                    Home
                  </button>
                </li>
                <li className="breadcrumb-item">
                  <button
                    type="button"
                    className="btn btn-link mr-0 p-0"
                    onClick={() => navigate("/panel/posts")}>
                    Posts
                  </button>
                </li>
                <li className="breadcrumb-item active">{isEdit ? 'Edit Post' : 'Create Post'}</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <div className="card card-default">
              <div className="card-header bg-light">
                <h3 className="card-title">{isEdit ? 'Edit Post' : 'Create Post'}</h3>
              </div>
              {isLoading && <Loader />}
              {response && response.success && (
                <div
                  className="alert alert-success mt-2 alert-dismissible fade show"
                  role="alert">
                  {response.message}
                  <button
                    type="button"
                    className="close"
                    onClick={closeAlert}
                    aria-hidden="true">
                    x
                  </button>
                </div>
              )}
              {response && !response.success && (
                <div
                  className="alert alert-danger mt-2 alert-dismissible fade show"
                  role="alert">
                  {response.message}
                  <button
                    type="button"
                    className="close"
                    onClick={closeAlert}
                    aria-hidden="true">
                    x
                  </button>
                </div>
              )}
              <form onSubmit={submitHandler}>
                <div className="card-body">
                <div className="form-group">
                    <label htmlFor="content form-label">Post Name <i className={`fas fa-question-circle ${classes.pointer}`} data-tooltip-id="name-tooltip" data-tooltip-html="The name is just for reference, <br>it will not pe posted on linkedin."></i></label>
                    <Tooltip id="name-tooltip" />
                    <input type="text"
                      autoFocus="true"
                      className={`form-control ${
                        validateValues.content.invalid ? "is-invalid" : ""
                      }`}
                      name="name"
                      id="name"
                      placeholder="Name of the post for reference"
                      value={postValues.name}
                      onChange={changeHandler}
                      aria-describedby="name-error"
                      aria-invalid="true"
                    />
                    <span id="name-error" className="error invalid-feedback">
                      {validateValues.name.message}
                    </span>
                  </div>
                  <div className="form-group">
                    <label htmlFor="content form-label">Post Content <i className={`fas fa-question-circle ${classes.pointer}`} data-tooltip-id="content-tooltip" data-tooltip-html="Enter the content that you <br> want to post on linkedin."></i></label>
                    <Tooltip id="content-tooltip" />
                    <textarea
                      type="textarea"
                      className={`form-control ${
                        validateValues.content.invalid ? "is-invalid" : ""
                      }`}
                      name="content"
                      id="content"
                      rows="8"
                      placeholder="What do you want to post about?"
                      value={postValues.content}
                      onChange={changeHandler}
                      aria-describedby="content-error"
                      aria-invalid="true"
                    />
                    <span id="content-error" className="error invalid-feedback">
                      {validateValues.content.message}
                    </span>
                  </div>
                  <div className="row">
                    <div className="form-group col-6 d-flex flex-column">
                      <label htmlFor="clientSecret" className="mb-2">
                        Status:
                      </label>
                      <div>
                        <span className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="status"
                            value="publish"
                            checked={postValues.status==='publish'}
                            onChange={changeHandler}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="inlineRadio1">
                            Publish
                          </label>
                        </span>
                        <span className="form-check form-check-inline">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="status"
                            value="draft"
                            checked={postValues.status==='draft'}
                            onChange={changeHandler}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="inlineRadio2">
                            Draft
                          </label>
                        </span>
                      </div>
                    </div>
                    <div className="form-group col-6 d-flex flex-column">
                      <label>Publish at: <i className={`fas fa-question-circle ${classes.pointer}`} data-tooltip-id="publishTime-tooltip" data-tooltip-html="Choose the time at which the post should be posted on your account. <br> If status is publish and nothing is selected then post will be posted instantly."></i></label>
                      <Tooltip id="publishTime-tooltip" />
                      {/* <DateTimePicker /> */}
                      <input
                        className={`form-control ${
                          validateValues.publishTime.invalid ? "is-invalid" : ""
                        }`}
                        type="datetime-local"
                        id="publishTime"
                        name="publishTime"
                        onChange={changeHandler}
                        disabled={postValues.status === "draft"}
                        placeholder="enter date"
                        aria-describedby="publishTime-error"
                        aria-invalid="true"
                        value={postValues.publishTime}
                      />
                      <span id="publishTime-error" className="error invalid-feedback">
                      {validateValues.publishTime.message}
                    </span>
                    </div>
                  </div>

                  <div className="form-group float-left">
                    <button
                      type="submit"
                      id="userSave"
                      className="btn btn-primary ">
                      <i
                        className="fa fa-save"
                        style={{ marginRight: 5 + "px" }}></i>
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PostCreateUpdate;
