import React, { useEffect, useState } from "react";
import AuthContext from "./AuthContext";
import Loader from "../../Components/Loader";

const AuthProvider = (props) => {

    console.log('in auth provider');
    const [loggedInUser, setLoggedInUser] = useState(null);
    const [token, setToken] = useState(null);
    // const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [fetchUserInfo, setFetchUserInfo] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        // console.log('inside use effect');
        const localStorageToken = localStorage.getItem('linkedin-scheduler-token');
        // console.log(localStorageToken);
        // alert('inside use effect');
        const fetchUserInfo = async (localStorageToken2) => {
            setIsLoading(true);
            const response = await fetch(process.env.REACT_APP_EXPRESS_URL + '/auth/user-info',{
                headers : {
                    Authorization: 'Bearer ' + localStorageToken2
                },
            })

            // alert(localStorageToken2);
    
            if(!response.ok) {
                if(response.status === 401) {
                    localStorage.removeItem('linkedin-scheduler-token');
                    return window.location.href="/login";
                  }
                // console.log('repsonse is bad, resetting state');
                // alert('bad response');
                setToken(null);
                // setIsLoggedIn(false);
                setLoggedInUser(null);
                return;
            } 

                const data = await response.json();
                // alert('succes response');
                // if(!loggedInUser && data.user) {
                    // console.log('user found and user is not set');
                    // console.table(data);
                    setLoggedInUser(data.user);
                // }
                
                // alert('nexct');
                setIsLoading(false);
                // alert('new');
                // alert(loggedInUser.email)
                // setIsLoggedIn(true);
        }

        if(localStorageToken) {
            // alert('toeknn fond');
            fetchUserInfo(localStorageToken);
            setToken(localStorageToken);
            setFetchUserInfo(false);
        } 
        // else {
        //     // setToken(null);
        //     setLoggedInUser(null);
        //     // setIsLoggedIn(false);
        // }
    }, [fetchUserInfo]);

    // const storeUserInfo = (user) => {
    //     console.table(user);
    //     setLoggedInUser(user);
    //     console.log('after setting user');
    //     console.log(loggedInUser);
    // };

    // const storeToken = (token2) => {
    //     setToken(token2);
    // };

    const reFetchUserInfo = (value) => {
        setFetchUserInfo(value);
    }

    const context = {
        userInfo: loggedInUser,
        // storeUserInfo: storeUserInfo,
        token : token,
        // storeToken : storeToken,
        // isLoggedIn : isLoggedIn,
        setFetchUserInfoAgain: reFetchUserInfo,
        fetchUserInfoAgain : fetchUserInfo
    }
    // console.log('in auth provider');    
    // console.table(context);

    // alert('in context', loggedInUser?.email);
    // alert();
    // console.log('in context', loggedInUser?.email);
    return(
        <AuthContext.Provider value={context}>
            {/* {props.children} */}
            {isLoading ? (<Loader />
      ) : (
        props.children
      )}
        </AuthContext.Provider>
    );
};

export default AuthProvider;