import { useNavigate } from "react-router-dom";
import Post from "./Post";
import { useContext, useEffect, useState } from "react";
import AuthContext from "../../Contexts/Auth/AuthContext";
import Loader from "../Loader";
import { Tooltip } from "react-tooltip";

const PostList = () => {
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);
  const [response, setResponse] = useState({});
  const [deleteApiResponse, setDeleteApiResponse] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [refetch, setRefetch] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(process.env.REACT_APP_EXPRESS_URL + "/posts", {
        headers: {
          Authorization: "Bearer " + authContext.token,
          "Content-Type": "application/json",
        },
        method: "GET",
      });

      if (!response.ok) {
        setResponse(null);
        return;
      }

      const data = await response.json();

      console.table(data.data);
      setResponse(data.data ?? {});
      setRefetch(false);
    };

    fetchData();
  }, [authContext.token, refetch]);

  const deletePostHandler = async (id) => {
    setIsLoading(true);
    try {
      const response = await fetch(process.env.REACT_APP_EXPRESS_URL + "/post/" + id, {
        headers: {
          Authorization: "Bearer " + authContext.token,
          "Content-Type": "application/json",
        },
        method: "DELETE",
      });

      if (!response.ok) {
        if (response.status === 401) {
          localStorage.removeItem("linkedin-scheduler-token");
          return (window.location.href = "/login");
        }
        const error = await response.json();
        setDeleteApiResponse({ success: false, message: error.message });

        return;
      }

      const data = await response.json();
      setDeleteApiResponse({ success: true, message: data.message });
      setRefetch(true);

      // alert(data.message);
    } catch (error) {
      setDeleteApiResponse({
        success: false,
        message: "Something went wrong. Please try again.",
      });
    } finally {
      setIsLoading(false);
      setTimeout(() => {
        // setRefetch(true);
        closeAlert();
      },3000)
    }
  };

  const closeAlert = () => setDeleteApiResponse(null);

  return (
    <div className="content-wrapper">
      <div className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="m-0">Post</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <button
                    type="button"
                    className="btn btn-link mr-0 p-0"
                    onClick={() => navigate("/panel/dashboard")}>
                    Home
                  </button>
                </li>
                <li className="breadcrumb-item active">Post</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <div className="card card-default">
              <div className="card-header bg-light">
                <h3 className="card-title">List of Posts</h3>
                <span className="float-right"><i className="fas fa-plus" style={{ cursor: 'pointer' }} onClick={() => navigate('/panel/post')}data-tooltip-id="create-tooltip" data-tooltip-html="Create post"></i></span>
                <Tooltip id="create-tooltip" />
              </div>
              {isLoading && <Loader />}
              {deleteApiResponse && deleteApiResponse.success && (
                <div
                  className="alert alert-success mt-2 alert-dismissible fade show"
                  role="alert">
                  {deleteApiResponse.message}
                  <button
                    type="button"
                    className="close"
                    onClick={closeAlert}
                    aria-hidden="true">
                    x
                  </button>
                </div>
              )}
              {deleteApiResponse && !deleteApiResponse.success && (
                <div
                  className="alert alert-danger mt-2 alert-dismissible fade show"
                  role="alert">
                  {deleteApiResponse.message}
                  <button
                    type="button"
                    className="close"
                    onClick={closeAlert}
                    aria-hidden="true">
                    x
                  </button>
                </div>
              )}
              <div className="card-body">
                {response.length ? (
                  response.map((post) => (
                    <Post
                      key={post._id}
                      data={post}
                      deletePost={deletePostHandler}
                    />
                  ))
                ) : (
                  <div className="d-flex justify-content-center">You have not created any post yet!</div>
                )}
                {/* <Post /> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PostList;
