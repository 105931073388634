import Header from '../Layout/Header';
import Footer from '../Layout/Footer';
import Sidebar from '../Layout/Sidebar';
import { Outlet } from 'react-router-dom';
import { useContext, useEffect } from 'react';
import AuthContext from '../../Contexts/Auth/AuthContext';
import AuthProvider from '../../Contexts/Auth/AuthProvider';

const DashboardNavigation = ({Component}) => {

    const authContext = useContext(AuthContext);
    // console.log('in dashboard navigation component');
    
    return (
        <>
            <Header />
            <Sidebar /> 
            {/* <Component/> */}
            <Outlet />
            <Footer />
        </>
    );
}

export default DashboardNavigation;