import React from "react";
import styled from 'styled-components'

const CenteredLoader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
//   background-color: rgba(0, 0, 0, 0.3);
  z-index: 999;
`;

const Loader = () => {
  return (
    <CenteredLoader>
      <div class="spinner-border text-primary" role="status">
        <span class="visually-hidden"></span>
      </div>
    </CenteredLoader>
  );
};

export default Loader;
